import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { List, ListItem } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ShareIcon from '@material-ui/icons/Share';
import { useMap } from './MapContext';
import { fromLonLat } from 'ol/proj';



const ToolMap = (center) => {
  const { map } = useMap();

  
  const handleShare= () => {
    if (map) {
      const layerById = map.getLayers().getArray().find(layer => layer.get('id_data') === 117);
   
    
    }
  };




  const handleZoomIn = () => {
    if (map) {
      const view = map.getView();
      const currentZoom = view.getZoom();
      view.setZoom(currentZoom + 1);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      const view = map.getView();
      const currentZoom = view.getZoom();
      view.setZoom(currentZoom - 1);
    }
  };

  const handleMRDA = async () => {
    if (map) {
      try {
        // Centrar y animar la vista para enfocar el polígono

        const findVectorLayerById = (id) => {
          const mapLayers = map.getLayers().getArray();
          return mapLayers.find(layer => layer.get('id_data') === id);
        };


            const layer_ac = findVectorLayerById('base');
         
          
            const features = layer_ac.getSource().getFeatures();
            const extent = features[0].getGeometry().getExtent();
            const centerM = fromLonLat(center.center);
            const height = extent[3] - extent[1]; // Altura en unidades de coordenadas
        
            const mapSize = map.getSize();
            const windowHeight = mapSize[1]; // Altura del mapa en píxeles
        
            const resolution = height / windowHeight;
            const zoom = Math.log2(156543.03392 / resolution); // 156543.03392 es el tamaño de un píxel en la resolución máxima (zoom 0)
       
            map.getView().setCenter(centerM);
            map.getView().setZoom(zoom-0.5);


            // map.getView().fit([-9013444.927302686, -2064439.9325589212, -7406432.844635141, -901374.1101716793], { duration: 1000 }); // Ajusta la duración de la animación según lo desees
      } catch (error) {
        console.error('Error loading GeoJSON:', error);
      }
    }
  };

  return (
    <div style={{
      position: 'absolute',
      bottom: '40px',
      right: '0',
      paddingRight :'10px',
      minHeight: '100px',
      zIndex: '98',
    }}>
      <List >
        <ListItem button style={{ padding: '4px' }}>
          <IconButton style={{ backgroundColor: 'white', borderRadius: '50%', padding: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' } }} onClick={handleZoomIn}>
            <ZoomInIcon />
          </IconButton>
        </ListItem>
        <ListItem button style={{ padding: '4px' }}>
          <IconButton style={{ backgroundColor: 'white', borderRadius: '50%', padding: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' } }} onClick={handleZoomOut}>
            <ZoomOutIcon />
          </IconButton>
        </ListItem>
        <ListItem button style={{ padding: '4px' }}>
          <IconButton style={{ backgroundColor: 'white', borderRadius: '50%', padding: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' } }} onClick={handleMRDA}>
            <CropFreeIcon />
          </IconButton>
        </ListItem>
        <ListItem button style={{ padding: '4px' }}>
          <IconButton style={{ backgroundColor: 'white', borderRadius: '50%', padding: '8px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', '&:hover': { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' } }} onClick={handleShare}>
            <ShareIcon />
          </IconButton>
        </ListItem>
      </List>
    </div>
  );
};

export default ToolMap;
