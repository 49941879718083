  
  import React, { useState } from 'react';
  import Button from '@mui/material/Button';
  import Box from '@mui/material/Box';
  import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
  import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
  import SearchBox from './SearchBox';
  import GreenAccordion from './GreenAccordion';
  import { useMap } from './MapContext';
  import Typography from '@mui/material/Typography';
  import ReactHtmlParser from 'react-html-parser'; // Import the library

  const LeftBar = ({data,title}) => {

    data=data;
    const { map } = useMap();
    const [searchQuery, setSearchQuery] = useState('');
    const [collapsed, setCollapsed] = useState(false);
  
    const handleSearch = (query) => {
      setSearchQuery(query);

    };
  
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    return (
      <Box
      className="scrollable-content"
        sx={{
          position: 'absolute',
    top: '75px',
    left: 0,
    width: collapsed ? 80 : 380,
    backgroundColor: 'white',
    transition: 'width 0.3s ease',
    border: '1px solid black',
    zIndex: 98,
    height: collapsed ?40 : 'calc(100% - 75px)', // Ajusta la altura según el estado collapsed
    overflow: collapsed ? 'hidden' : 'auto', 
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px',
          }}
        >
         {collapsed ? null
       
        :   <Typography 
        sx={{
          fontFamily: 'Lato',
          fontSize: '20px',
          fontWeight: '800',
          lineHeight: '20px',
          textAlign: 'left',
          padding:'10px'
        }}
  >
       {ReactHtmlParser(title)} {/* Render title as HTML */}
  
        </Typography>}
          <Button
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
            onClick={toggleCollapse}
          >
            {collapsed ? <KeyboardTabIcon /> : <KeyboardBackspaceIcon />}
          </Button>
        </Box>
  
        <div style={{ visibility: collapsed ? 'hidden' : 'visible' }}>
          <SearchBox onSearch={handleSearch} />
          <Box>
            <GreenAccordion map={map} items={data} />
          </Box>
        </div>
      </Box>
    );
  };
  
  export default LeftBar;