import React from 'react';

import { useTheme } from "@mui/material/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  appBar: {
    height: 75,
    background: 'linear-gradient(90deg, #1B877C 74.5%, #FFFFFF 85%), #1D4B6F',
    position: 'absolute', // Posición absoluta
    boxShadow: '0px 0px 8px 0px #00000033',
    top: 0, // Colocado en la parte superior
    width: '100%', // Ancho completo
    zIndex: 999, // Z-index para asegurar que esté encima de otros elementos
  },
  logoContainerLeft: {
    height: '75px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoContainerRight: {
    height: '75px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize:'19px',
    textAlign:'center'
  },
  logo: {
    height: 'auto', // Ajusta el tamaño del logotipo según la altura del contenedor
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

function TopBar(objeto) {
  const classes = useStyles();
  const slogan=objeto.objeto.slogan;
  const slogan2=objeto.objeto.slogan2;
  const logo=objeto.objeto.logo;
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid item xs={4} className={classes.logoContainerLeft}>
            <img src='./img/logo_greenopolis.svg' alt="Logo 1" className={classes.logo} />
          </Grid>
          <Grid item xs={5} className={classes.logoContainerRight}>
                    <div>
              <label style={{ fontSize: "19px" }}>{slogan}</label>
              <br />
              <label style={{ fontSize: "16px",fontStyle: "italic"  }}>{slogan2}</label>
          </div>
                  
          </Grid>
          <Grid item xs={3} className={classes.logoContainerRight}>
            <img src={logo} alt="Logo 2" className={classes.logo} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
