// MapComponent.js
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from './MapContext';
import Popup from 'ol-popup';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import proj4 from 'proj4'; // Importa la biblioteca proj4 para conversiones de coordenadas
import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON'; // Agregar esta línea
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Fill, Stroke } from 'ol/style';
import { transform } from 'ol/proj';
import numeral from 'numeral';
import XYZ from 'ol/source/XYZ';
import ImageLayer from 'ol/layer/Image'; // Importa la clase ImageLayer
import ImageWMS from 'ol/source/ImageWMS'; // Importa la clase ImageWMS
import Overlay from 'ol/Overlay';
import { altKeyOnly, click, pointerMove } from 'ol/events/condition.js';
import Select from 'ol/interaction/Select';
import { unByKey } from 'ol/Observable';
import { easeOut } from 'ol/easing';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import { createXYZ } from 'ol/tilegrid';

const MapComponent = (objeto) => {

    const centerM = objeto.objeto.center;
    const MapContorno = objeto.objeto.contorno;
    const MapMascara = objeto.objeto.mascara;
    const data = objeto.objeto.data;


    const { setMap } = useMap(); // Obtiene la función setMap del contexto
    const mapRef = useRef(null);

    const [selectedFeature, setSelectedFeature] = useState(null); // Estado para la característica seleccionada
    const [originalStyle, setOriginalStyle] = useState(null); // Estado para el estilo original de la característica

    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
    const [utmZone, setUtmZone] = useState('');
    const [northing, setNorthing] = useState('');
    const [easting, setEasting] = useState('');

    function findElementById(id) {
        const stack = [...data]; // Utilizamos una pila para realizar un recorrido iterativo

        while (stack.length > 0) {
            const current = stack.pop(); // Sacamos el último elemento de la pila

            if (current.id === id)
                return current;
            if (current.children && current.children.length > 0)
                stack.push(...current.children); // Agregamos los hijos del elemento actual a la pila

        }

        return null; // Si no se encuentra el elemento con el ID especificado, retornamos null
    }


    useEffect(() => {
        let LineSelect = 0;
        let StyleSelect = [];
        const fetchMRDA = async () => {
            const vectorSourceMascara = new VectorSource({
                url: MapMascara,
                format: new GeoJSON(),
            });

            const vectorSource = new VectorSource({
                url: MapContorno,
                format: new GeoJSON(),
            });


            const vectorSourceLimite = new VectorSource({
                url: '/layers/mrda_limite.json',
                format: new GeoJSON(),
            });
            const vectorLayerLimite = new VectorLayer({
                source: vectorSourceLimite,
                style: [new Style({

                    stroke: new Stroke({
                        color: "#E4C46B",
                        width: 4
                    })
                })]

            });


            const vectorLayer = new VectorLayer({
                source: vectorSource,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0)' // Relleno transparente
                    }),
                    stroke: new Stroke({
                        color: "#1C887D",
                        width: 2
                    })
                }),

            });

            const vectorLayerMascara = new VectorLayer({
                source: vectorSourceMascara,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(10, 10, 10,0.7)' // Relleno transparente
                    }),
                    stroke: new Stroke({
                        color: '#1C887D',
                        width: 5
                    })
                }),
                zIndex: 15
            });

            vectorLayer.set('id_data', 'base');
            vectorLayerLimite.set('id_data', 'base');
            map.addLayer(vectorLayer);
            map.addLayer(vectorLayerMascara);
            map.addLayer(vectorLayerLimite);

            await new Promise((resolve, reject) => {
                vectorSource.once('change', () => {
                    if (vectorSource.getState() === 'ready') {
                        resolve();
                    } else {
                        reject(new Error('Failed to load GeoJSON'));
                    }
                });
            });


            const features = vectorSource.getFeatures();
            const extent = features[0].getGeometry().getExtent();
            const center = fromLonLat(centerM);
            const height = extent[3] - extent[1]; // Altura en unidades de coordenadas

            const mapSize = map.getSize();
            const windowHeight = mapSize[1]; // Altura del mapa en píxeles

            const resolution = height / windowHeight;
            const zoom = Math.log2(156543.03392 / resolution); // 156543.03392 es el tamaño de un píxel en la resolución máxima (zoom 0)

            map.getView().setCenter(center);
            map.getView().setZoom(zoom - 0.5);


            const popupmore = new Popup();
            map.addOverlay(popupmore);

            // Crear el elemento HTML para el fondo gris
            const overlayBackgroundElement = document.createElement('div');
            overlayBackgroundElement.id = 'overlayBackground';
            overlayBackgroundElement.style.position = 'absolute';
            overlayBackgroundElement.style.top = '0';
            overlayBackgroundElement.style.left = '0';
            overlayBackgroundElement.style.width = '100px';
            overlayBackgroundElement.style.height = '100px';
            overlayBackgroundElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
            overlayBackgroundElement.style.zIndex = '9998';

            // Crear el overlay con el elemento HTML
            var backgroundOverlay = new Overlay({
                position: 0,
                element: overlayBackgroundElement,
                positioning: 'center-center',
                stopEvent: false// Esto evitará que los eventos del mapa se propaguen a través del fondo
            });

            // Añadir el overlay al mapa
            map.addOverlay(backgroundOverlay);
            const popup = new Popup();
            map.addOverlay(popup);
            const selectFeat = null;
            map.on('click', function (event) {
                const transformedCoordinate = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
                const longitude = transformedCoordinate[0];
                const latitude = transformedCoordinate[1];

                const feature = map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                    // Verificamos si la capa es la capa de puntos
                    if (feature.getGeometry().getType() === 'Point' || feature.getGeometry().getType() === 'LineString' || feature.getGeometry().getType() === 'MultiLineString' || feature.getGeometry().getType() === 'Polygon' || feature.getGeometry().getType() === 'MultiPolygon') {
                        return feature;
                    }
                });
                const layerhand = map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                    // Verificamos si la capa es la capa de puntos
                    return layer;
                });

            
                if (layerhand.get('id_data') != 'base') {
                    if (feature) {
                        const properties = feature.getProperties();

                        let coordinates;
                        let existingStyle = []
                 
                        if (layerhand.getRenderer().constructor.name !== 'WebGLVectorLayerRenderer' && layerhand.getRenderer().constructor.name !== 'uv') {
                            existingStyle = layerhand.getStyle();
                        }

                        const additionalStyle = new Style({
                            stroke: new Stroke({
                                color: '#87CEEB', // Color del fondo
                                width: 12,
                                lineCap: 'square',
                                lineJoin: 'bevel'
                            })
                        });

                        if (feature.getGeometry().getType() === 'LineString') {
                            coordinates = event.coordinate;
                            if (layerhand.get('id_data') != 'base') {

                                const newStyle = [additionalStyle]
                                if (existingStyle) {
                                    existingStyle.map(function (element) {

                                        newStyle.push(element)
                                    });
                                }
                                if (feature != LineSelect) {
                                    if (LineSelect != 0) {

                                        LineSelect.setStyle(StyleSelect);
                                    }

                                    feature.setStyle(newStyle)
                                    LineSelect = feature;
                                    StyleSelect = existingStyle;
                                }
                            }
                        }

                        else if (feature.getGeometry().getType() === 'MultiLineString') {

                            coordinates = event.coordinate;
                            const newStyle = [additionalStyle]
                            if (existingStyle) {
                                existingStyle.map(function (element) {

                                    newStyle.push(element)
                                });
                            }
                            if (feature != LineSelect) {
                                if (LineSelect != 0) {

                                    LineSelect.setStyle(StyleSelect);
                                }

                                feature.setStyle(newStyle)
                                LineSelect = feature;
                                StyleSelect = existingStyle;
                            }


                        }

                        else if (feature.getGeometry().getType() === 'Polygon') {
                            coordinates = event.coordinate;
                        }
                        else if (feature.getGeometry().getType() === 'MultiPolygon') {

                            coordinates = event.coordinate;
                        }
                        else {

                            coordinates = feature.getGeometry().getCoordinates();
                        }

                        const truncateText = (text, maxLength = 100) => {
                            if (text && text.length > maxLength) {
                                return `${text.substring(0, maxLength)}...`;
                            }
                            return text;
                        }

                        const layer_ac = findElementById(layerhand.get('id_data'));

                        if (layer_ac) {
                            if (!layer_ac.meta) {
                                let descLength = 0;
                                if (feature.getProperties().desc)
                                    descLength = feature.getProperties().desc.length;

                                const minContentWidth = descLength > 100 ? '390px' : '288px'; // Define el ancho mínimo como 350px si la longitud de la descripción es mayor a 100, de lo contrario, el ancho será automático
                                popup.show(coordinates, `
                                <div style="position:absolute;top:-175px;margin-bottom:50px;padding-top:30px;margin-right:40px;">
                                    <div style="position:absolute;left:10;top:140px;"><img height="48" src="./img/triangle.svg" /></div>
                                    <div class="popover-content" style="min-width: ${minContentWidth};">
                                        <button class="close-button">X</button>
                                        ${descLength > 100 ? '<button class="readmore-button"><img src="./img/icon/read_more.svg" /></button>' : ''}
                                        <ul style="padding-left: 30px;margin-top: 0px;">
                                        ${feature.getProperties().desc ? `<li><b>Descripción:</b> <br>${feature.getProperties().desc ? truncateText(feature.getProperties().desc) : ''}</li>` : ''}
                                            ${feature.getProperties().costo ? `<li><b>Costo:</b> <br>${feature.getProperties().costo ? feature.getProperties().costo : ''}</li>` : ''}
                                            ${feature.getProperties().beneficio ? `<li><b>Beneficios:</b>  <br>${feature.getProperties().beneficio ? truncateText(feature.getProperties().beneficio) : ''}</li>` : ''}
                                            ${feature.getProperties().estado ? `<li><b>Estado:</b>  <br>${truncateText(feature.getProperties().estado)}</li>` : ''}
                                            ${layer_ac.link ? `<li><b>Link:</b>  <br>  <button id="myButton" onclick="window.open('${truncateText(layer_ac.link)}', '_blank');">
                                            <img src="/img/report-svgrepo-com.svg" alt="Report Icon">
                                            ProInversión
                                          </button> </li>` : ''}
                                        </ul>
                                    </div>
                                </div>
                            `);

                            }
                            else {
                                let html = '';
                                let showReadMoreButton = false; // Inicialización de showReadMoreButton
                                let minContentWidth = '288px'; // Ancho mínimo predeterminado

                                layer_ac.meta.forEach(element => {
                                    let feature_actual;
                                    if (feature.get('features')) {
                                        feature_actual = feature.get('features')[0];
                                    } else {
                                        feature_actual = feature;
                                    }

                                    let value = element.value;
                                    let featureValue = feature_actual.get(value) || '';

                                    if (featureValue.length > 100) {
                                        minContentWidth = '405px';
                                        return;
                                    }
                                });

                                if (layer_ac.meta.length === 1) {
                                    // Si hay solo un elemento en layer_ac.meta, no aplicar el estilo top:-175
                                    html += `<div style="position:absolute;margin-bottom:50px;margin-right:40px;">`;
                                    // Verificar si la longitud del valor es mayor que 100 para ajustar el ancho mínimo

                                } else {
                                    // Si hay más de un elemento en layer_ac.meta, aplicar el estilo top:-175
                                    html += `<div style="position:absolute;top:-175px;margin-bottom:50px;padding-top:30px;margin-right:40px;">
                                        <div style="position:absolute;left:10;top:140px;"><img height="48" src="./img/triangle.svg" /></div>`;
                                }
                                const size = map.getSize();
                                console.log('Tamaño del espacio:', size);
                                console.log('Resolución en píxeles:', resolution);
                                const unit_la= layer_ac.meta.length>5?layer_ac.meta.length:6;
                               
                                let popoverContentTop = 0;

                                if(MapContorno=='layers/ayacucho_feature.geojson'){
                                    popoverContentTop=-100;                                
                                }
                                
                                
                                html += `
                                    <div class="popover-content" style="min-width: ${minContentWidth};top:${popoverContentTop}px" ;   overflow-y: auto;     /* Habilita el desplazamiento vertical */
  overflow-x: hidden;   /* Evita el desplazamiento horizontal */> <!-- Aplicación del ancho mínimo -->
                                        <button class="close-button">X</button>
                                        <ul style="padding-left: 30px;margin-top: 0px;">`;

                                // Agregar elementos de la lista
                                let ruta_cod_foto=  "";
                             

                                layer_ac.meta.forEach(element => {
                                    let feature_actual;
                                    if (feature.get('features')) {
                                        feature_actual = feature.get('features')[0];
                                    } else {
                                        feature_actual = feature;
                                    }

                                    let value = element.value;
                                    let formatoMo='';
                                    let desc = element.desc;
                                 
                                    if(value=='cui'){
                                        ruta_cod_foto=feature_actual.get(value);
                                    }
                                 
                                    let featureValue = feature_actual.get(value) || '0';
                                    if(element.formato){
                                   
                                        let letra = featureValue.toString();
                                        let numero = parseFloat(letra); // Convertir a número por si es necesario
                                        formatoMo= new Intl.NumberFormat('es-PE', {
                                          style: 'currency',
                                          currency: 'PEN',
                                          minimumFractionDigits: 2
                                        }).format(numero);

                                    
                                    }
                                    else{
                                        formatoMo=featureValue.toString();
                                   }
                                    if(element.mef){
                                        formatoMo=featureValue.toString()+` <button style="background-color: red; color: white; font-size: 12px; padding: 4px 8px; border: none; border-radius: 4px; cursor: pointer; font-family: 'Roboto', sans-serif;" 
                                            onmouseover="this.style.backgroundColor='darkred'" 
                                            onmouseout="this.style.backgroundColor='red'" 
                                            onclick="window.open('https://ofi5.mef.gob.pe/inviertews/Repseguim/ResumF12B?codigo=${featureValue.toString()}', '_blank')">
                                            MEF
                                            </button>
                                            `;
                                    }
                                
                                    html += `<li><b>${desc}:</b><br>${formatoMo}</li>`;

                                    // Verificar si la longitud del valor es mayor que 100 para mostrar el botón de "Leer más"
                                    if (featureValue.length > 100) {
                                        showReadMoreButton = false;
                                    }
                                });

                                html += `</ul>`;

                                if(layer_ac.isfoto){
                                   html+=`<img src="${layer_ac.ruta+ruta_cod_foto+'.jpg'}" class="imagen-ajustada" alt="Descripción de la imagen"   onerror="this.style.display='none';">`
                                }


                                if (showReadMoreButton) {
                                    html += `<button class="readmore-button" onclick="expandPopoverContent()"><img src="./img/icon/read_more.svg" /></button>`;
                                }
                                html += `</div>
                                    </div>`;

                                popup.show(coordinates, html);

                            }

                            map.getView().animate({
                                center: fromLonLat(transformedCoordinate),
                                duration: 500, // Duración de la animación en milisegundos (2 segundos en este ejemplo)

                            });

                            const closeButton = document.querySelector('.close-button');
                            closeButton.addEventListener('click', () => {
                                popup.hide();
                            });



                        } else {
                        }
                        function expandPopoverContent() {
                            let descLength = 0;
                            if (feature.getProperties().desc)
                                descLength = feature.getProperties().desc.length;
                            const popoverContentTop = -50;
                            const minContentWidth = descLength > 100 ? '420px' : '288px';
                            popup.show(coordinates, `
                            <div style="position:absolute;top:-175px;margin-bottom:50px;padding-top:30px;margin-right:40px;">
                                <div style="position:absolute;left:10;top:140px;"><img height="48" src="./img/triangle.svg" /></div>
                                <div class="popover-content" style="min-width: ${minContentWidth};top:${popoverContentTop}px;">
                                    <button class="close-button">X</button>
                                    ${descLength > 100 ? '<button class="readmore-button"><img src="./img/icon/read_more.svg" /></button>' : ''}
                                    <ul style="padding-left: 30px;margin-top: 0px;">
                                    ${feature.getProperties().desc ? `<li><b>Descripción:</b> <br>${feature.getProperties().desc ? feature.getProperties().desc : ''}</li>` : ''}
                                        ${feature.getProperties().costo ? `<li><b>Costo:</b> <br>${feature.getProperties().costo ? feature.getProperties().costo : ''}</li>` : ''}
                                        ${feature.getProperties().beneficio ? `<li><b>Beneficios:</b>  <br>${feature.getProperties().beneficio ? feature.getProperties().beneficio : ''}</li>` : ''}
                                        ${feature.getProperties().estado ? `<li><b>Estado:</b>  <br>${feature.getProperties().estado}</li>` : ''}
                                        ${layer_ac.link ? `<li><b>Link:</b>  <br>  <button id="myButton" onclick="window.open('${layer_ac.link}', '_blank');">
                                        <img src="/img/report-svgrepo-com.svg" alt="Report Icon">
                                        ProInversión
                                      </button> </li>` : ''}
                                    </ul>
                                </div>
                            </div>`)

                            const closeButton = document.querySelector('.close-button');
                            closeButton.addEventListener('click', () => {
                                popup.hide();
                            });

                        }
                        const readmoreButton = document.querySelector('.readmore-button');
                        if (readmoreButton) {
                            readmoreButton.addEventListener('click', () => {
                                expandPopoverContent();
                                // const size = map.getSize();
                                // const resolution = map.getView().getResolution();


                                // // Obtener el elemento overBlack
                                // // const overBlackElement = document.getElementById('overBlack');
                                // // // Mostrar el elemento cambiando su estilo
                                // // overBlackElement.style.display = 'block';

                                // // Calcular el alto del contenido del popover


                                // // Mostrar el popover
                                // popupmore.show(coordinates, `
                                //     <div style="margin-bottom:50px;margin-right:40px;z-index:999;">
                                //         <div class="popover-more-content" style="top:${popoverContentTop}px">
                                //         <button class="close-button" id= "close-12">X</button>
                                //         <ul style="padding-left: 30px;margin-top: 0px; overflow-y: auto; max-height: ${popoverContentHeight}px;">
                                //             <li><b>Descripción:</b><br>${feature.getProperties().desc ? feature.getProperties().desc : ''}</li>
                                //             <li><b>Costo:</b><br>${feature.getProperties().costo ? feature.getProperties().costo : ''}</li>
                                //             <li><b>Beneficios:</b><br>${feature.getProperties().beneficio ? feature.getProperties().beneficio : ''}</li>
                                //             <li><b>Estado:</b><br>${feature.getProperties().estado ? feature.getProperties().estado : ''}</li>
                                //         </ul>
                                //         </div>
                                //     </div>
                                // `);


                                // const closeButtonMore = document.querySelector('#close-12');
                                // if (closeButtonMore) {
                                //     closeButtonMore.addEventListener('click', () => {
                                //         popupmore.hide();
                                //     });
                                // }

                            });

                        }
                    }
                    else {
                    }

                    
                }

            });

            map.getView().on('change:resolution', function () {
                const zoom = map.getView().getZoom();
                // Define zoom levels for showing labels
                const minZoomToShowLabels = 5;
                const maxZoomToShowLabels = 1000000;
                const view = map.getView();
                // Obtener la resolución actual del mapa
                const resolution = view.getResolution();
                // Obtener la unidad de distancia en metros del mapa
                const projection = view.getProjection();
                const metersPerUnit = projection.getMetersPerUnit();
                // Calcular la escala
                const scale = resolution * metersPerUnit * 39.37 * 72; // 39.37 pulgadas por metro, 72 puntos por pulgada
                const overlays = map.getOverlays().getArray();
                const layers = map.getLayers().getArray().filter(layer => layer instanceof VectorLayer);
                layers.forEach(layer => {
                    const vectorSource = layer.getSource();
                    // Obtener las características (features) de la fuente
                    const features = vectorSource.getFeatures();
                    features.forEach(feature => {
                        // Realizar operaciones con la característica (feature)
                        const overlay = feature.get('overlay');
                        if (overlay) {

                            if (scale >= maxZoomToShowLabels) {
                                overlay.getElement().style.display = 'none'; // Hide overlay
                            } else {
                                overlay.getElement().style.display = 'block'; // Show overlay
                            }
                        }
                        else {
                        }

                    });
                })
            });
        }
        const wmsUrl = 'https://espacialg.geoperu.gob.pe/geoserver/geoperu/wms?';
        const wmsParams = {
            'LAYERS': 'geoperu:peru_departamento_', // Nombre de la capa
            'VERSION': '1.1.1',
            'CQL_FILTER': "cod_dpto in ('05','08','09','11','12','03')",
            'FORMAT': 'image/png',
            'TRANSPARENT': true,
            'SRS': 'EPSG:4326'
        };
        const wmsSource = new ImageWMS({
            url: wmsUrl,
            params: wmsParams,
            serverType: 'geoserver',
            ratio: 1
        });
        const wmsLayer = new ImageLayer({
            source: wmsSource,
            zIndex: 2
        });

        // const vectorTileLayer = new VectorTileLayer({
        //     source: new VectorTileSource({
        //       format: new MVT(),
        //       url: 'http://localhost:8080/geoserver/gwc/service/tms/1.0.0/topp:Anemia_6a35m_ene2024@EPSG:4326@pbf/{z}/{x}/{-y}.pbf'
        //     })
        //   });

          const styleSimple = new Style({
            fill: new Fill({
             color: '#d7d7a8'
            }),
            stroke: new Stroke({
                color: '#319FD3',
              width: 1
            })
          });
      
          function simpleStyle() {
            return styleSimple;
          }
        const map = new Map({
            controls: [],
            target: mapRef.current,
            layers: [

                new TileLayer({
                    className: 'bw',
                    source: new XYZ({
                        url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
                    }),
                })
            ],
            view: new View({
                center: fromLonLat(centerM),
                zoom: 7,

            }),
            renderer: 'webgl'
        });

        fetchMRDA();
        setMap(map);
        // change mouse cursor when over marker
        map.on('pointermove', function (e) {

            const feature = map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {

                if (feature.getGeometry().getType() === 'Point' || feature.getGeometry().getType() === 'LineString' || feature.getGeometry().getType() === 'MultiLineString' || feature.getGeometry().getType() === 'MultiPolygon' || feature.getGeometry().getType() === 'Polygon') {
                    return feature;
                }
            });

            map.getTarget().style.cursor = feature ? 'pointer' : '';
        });
        const handleMouseMove = (event) => {
            const e = event.coordinate;
            const lonLat = transform(e, 'EPSG:3857', 'EPSG:4326');
            const lng = lonLat[0];
            const lat = lonLat[1];

            setCoordinates({ lng, lat });

            // Convertir coordenadas a UTM
            const utm = proj4('EPSG:4326', '+proj=utm +zone=18 +south +datum=WGS84', [lng, lat]); // EPSG:32612 es la proyección UTM para la zona 12N
            setUtmZone(getUtmZone(lat, lng)); // Obtener la zona UTM
            setNorthing(numeral(utm[0].toFixed(3)).format('0,0')); // Configurar el valor de la coordenada norte
            setEasting(numeral(utm[1].toFixed(3)).format('0,0')); // Configurar el valor de la coordenada este


        };

        const getUtmZone = (lat, lon) => {
            const zoneNumber = Math.floor((lon + 180) / 6) + 1;
            const hemisphere = lat >= 0 ? 'N' : 'S';
            return `${zoneNumber}${hemisphere}`;
        };
        map.on('pointermove', handleMouseMove);
        // Limpia el mapa al desmontar el componente
        return () => {
            map.setTarget(null);
            setMap(null);
        };
    }, [setMap, centerM, MapContorno, MapMascara, selectedFeature, originalStyle]);

    return (
        <div style={{ position: 'relative', height: 'calc(100vh )' }}>

            <div
                ref={mapRef}
                style={{ width: '100%', height: '100%', minHeight: '400px' }} // Ajusta las dimensiones según sea necesario
            ></div>

            <div style={{ position: 'absolute', bottom: 0, right: 0, }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', background: 'rgba(255, 255, 255, 0.8)' }}>
                    <div style={{ border: '1px solid black', padding: '5px' }}>GCS Longitud: {coordinates.lng.toFixed(3)}</div>
                    <div style={{ border: '1px solid black', padding: '5px' }}>Latitud: {coordinates.lat.toFixed(3)}</div>
                    <div style={{ border: '1px solid black', padding: '5px' }}>UTM Zona: {utmZone}</div>
                    <div style={{ border: '1px solid black', padding: '5px' }}>Norte: {northing}m</div>
                    <div style={{ border: '1px solid black', padding: '5px' }}>Este: {easting}m</div>
                </div>
            </div>
        </div>

    );
};

export default MapComponent;
