import React, { useState } from 'react';
import { CssBaseline } from "@mui/material";

import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import ToolMap from './components/ToolMap'
import TopBar from './components/TopBar'
import LeftBar from './components/LeftBar'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//NUEVA FORMA
import MapComponent from './components/MapComponent'
import JSONTableEditor from './components/JSONTableEditor'
import { MapProvider } from './components/MapContext'; // Importa el proveedor del contexto del mapa
import data from './data/layers.json';
import datav from './data/layers_vraem.json';
import datap from './data/layers_plibre.json';
import datac from './data/layers_cusco.json';
import dataayacucho from './data/layers_ayacucho.json';
import Popup from './components/Popup';
import { none } from 'ol/centerconstraint';

// Define el tema personalizado
const theme = createTheme({
  palette: {
    background: {
      default: "#F7F9FC"
    },
    primary: {
      main: "#1B877C"
    },
    secondary: {
      main: "#E3C46B"
    }
  },
  typography: {
    fontFamily: [
     
      'Lato', // Puedes cambiar esta fuente a tu preferencia
      'Merriweather',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  h2: {
    fontFamily: [
      'Lato', // Fuente en la posición 0
    ].join(','),
  },
});

const App = () => {

  const [layerVisible, setLayerVisible] = useState(true);
  const handleToggleLayer = () => {
    setLayerVisible(!layerVisible);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router> {/* Envuelve tu aplicación con el componente Router */}
          <Switch>
            <Route exact path="/">
              <div>
                <TopBar />
                <MapProvider>
                  <div>
                    <MapComponent />
                    <LeftBar />
                    <ToolMap />
                  </div>
                </MapProvider>
              </div>
            </Route>
            {/* Puedes agregar más rutas según sea necesario */}
            {/* Por ejemplo, para la ruta /mrda */}
            <Route path="/mrdla" >
              {/* Agrega el componente que deseas renderizar en /mrda */}
              <div>
                <TopBar objeto={{slogan:'Rumbo a la competitividad territorial de la Mancomunidad ', slogan2:'"La descentralización acelera el desarrollo..."',logo:'img/logo_mrda.svg'}} />
                <MapProvider>
                  <div>
                    <MapComponent objeto={{data:data,center:[-75.42531888786262, -13.036277894538287],contorno:'layers/mrda_feature.geojson',mascara:'layers/mrda_mascara_feature.geojson' }} />
                    <div id="overBlack" style={{  display:'none', position: "absolute", top: '0px',left: '0px',width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: '100'} }></div>
                    <LeftBar title="Proyectos<br /> Estratégicos" data={data}/>
                    <ToolMap center={[-75.42531888786262, -13.036277894538287]} />
                  </div>
                </MapProvider>
              </div>
            </Route>

            <Route path="/f32455be" >
              {/* Agrega el componente que deseas renderizar en /mrda */}
              <div>
                <TopBar objeto={{slogan:'Rumbo a la competitividad territorial de la Mancomunidad',logo:'img/logo_mrda.svg'}} />
                <MapProvider>
                  <div>
                    <MapComponent objeto={{data:datav,center:[-74.78558054466943, -12.198436920186793],contorno:'layers/vraem_feature.geojson',mascara:'layers/vraem_mascara_feature.geojson' }} />
                 
          
                 <div id="overBlack" style={{ display:'none',  position: "absolute", top: '0px',left: '0px',width: '100px',height: '100px',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: '100'} }></div>
                    <LeftBar title="Proyectos<br /> Estratégicos" data={datav}/>
                    <ToolMap center={[-74.78558054466943, -12.198436920186793]} />
                  </div>
                </MapProvider>
              </div>
            </Route>
            <Route path="/pueblolibre" >
              {/* Agrega el componente que deseas renderizar en /mrda */}
              <div>
                <TopBar objeto={{slogan:'',logo:'img/logo_plibre.png'}} />
                <MapProvider>
                  <div>
                    <MapComponent objeto={{data:datap,center:[-77.07304, -12.07514],contorno:'layers/plibre_feature.geojson',mascara:'layers/plibre_mascara_feature.geojson'}} />
                 <div id="overBlack" style={{ display:'none',  position: "absolute", top: '0px',left: '0px',width: '100px',height: '100px',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: '100'} }></div>
                    <LeftBar title="Gobernanza <br /> Territorial" data={datap}/>
                    <ToolMap center={[-77.07304, -12.07514]} />
                  </div>
                </MapProvider>
              </div>
            </Route>
            <Route path="/cusco" >
              {/* Agrega el componente que deseas renderizar en /mrda */}
              <div>
                <TopBar objeto={{slogan:'',logo:'img/logo_plibre.png'}} />
                <MapProvider>
                  <div>
                    <MapComponent objeto={{data:datac,center:[-72.044, -13.528],contorno:'layers/cusco_feature.geojson',mascara:'layers/cusco_mascara_feature.geojson'}} />
                 
          
                 <div id="overBlack" style={{ display:'none',  position: "absolute", top: '0px',left: '0px',width: '100px',height: '100px',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: '100'} }></div>
                    <LeftBar data={datac}/>
                    <ToolMap center={[-72.044, -13.528]} />
                  </div>
                </MapProvider>
              </div>
            </Route>
            <Route path="/editor" >
              {/* Agrega el componente que deseas renderizar en /mrda */}
              <div>
               <JSONTableEditor />
              </div>
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;