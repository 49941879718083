import React from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

function SearchBox({ onSearch }) {
  const handleChange = (event) => {
    // Llama a la función `onSearch` pasando el valor del campo de texto
    onSearch(event.target.value);
  };

  return (
    <div style={{ width: '90%', margin: '0 auto' }}>  <TextField 
      label="Buscar Item"
      variant="outlined"
      fullWidth
      onChange={handleChange}
     
    />
    </div>
  );
}

export default SearchBox;
